import {
  arrayIfNil,
  ensureArray,
  isTruthy,
  emptyObj,
  paramsCartesianProduct,
  getTypedEmptyArr,
} from 'utils/fp'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { generateObjMemoizer, memoize } from 'utils/misc'
import {
  either,
  equals,
  filter,
  isNil,
  path,
  paths,
  pick,
  pickAll,
  pipe,
  reject,
  zipObj,
} from 'ramda'
import { allKey } from 'app/constants'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { cacheStoreKey, loadingStoreKey, paramsStoreKey } from 'core/caching/cacheReducers'
import { RootState } from 'app/store'
import ListAction from 'core/actions/ListAction'
import { IDataKeys } from 'k8s/datakeys.model'
import { entityNamesByKey } from 'k8s/DataKeys'
import parseClusterIdsFromParams from 'app/plugins/infrastructure/components/combinedClusters/parseClusterIdsFromParams'
import { someAsync } from 'utils/async'

type ArrayedParamValues<ParamsType> = {
  [Property in keyof ParamsType]: ParamsType[Property] | Array<ParamsType[Property]>
}

interface Options<P> {
  params?: ArrayedParamValues<P>
  loadOnDemand?: boolean
  loadingFeedback?: boolean
  requiredParams?: Array<keyof P>
  preRequisites?: boolean
  // If loading is undefined in the cache, this will be used to determine the default value
  initialLoadingState?: boolean
  preserveParams?: boolean
}

const useIsDataLoadingSelector = (initialLoadingState, ...cacheKeys): string[] => {
  const keyPaths = useMemo(
    () => cacheKeys.map((cacheKey) => [cacheStoreKey, loadingStoreKey, cacheKey]),
    cacheKeys,
  )
  // Bug here where when loading up a resource for the first time, loading is
  // undefined, which is interpreted as loading false, AKA done loading.
  // On a wizard like EditTenantPage where the initial context depends on
  // this loading flag, the initial context loads prematurely and the wizard
  // won't get populated with the correct data.
  // Can also see this bug affecting NamespacesPicklist -- is reason why all key is always picked
  // Look into if updating wizard context when initial context changes makes sense to do

  // Note: set "initialLoadingState" option to true in these aforementioned edge cases
  const keysLoadingStatus = useSelector<RootState, boolean[]>(paths(keyPaths))

  return useMemo(() => {
    const loadingStatusByKey = zipObj(cacheKeys, keysLoadingStatus)
    return Object.keys(
      filter(initialLoadingState ? either(isTruthy, isNil) : isTruthy, loadingStatusByKey),
    )
  }, [cacheKeys, keysLoadingStatus])
}

// @todo We should try to simplify this hook without losing functionality
const useListAction = <
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R extends unknown[] = IDataKeys[D]
>(
  action: ListAction<D, P, R>,
  options: Options<P> = {},
): {
  message: string
  reload: (refetch?: boolean, updateLoadingState?: boolean) => Promise<void>
  loadingKeys: string[]
  loading: boolean
} => {
  const memoizeParams = useMemo(() => generateObjMemoizer<ArrayedParamValues<P>>(), [])
  const { config, dependencies: dependentCacheKeys } = action
  const { cacheKey, indexBy = getTypedEmptyArr<string>(), cache } = config
  const defaultParams = emptyObj as ArrayedParamValues<P>
  const {
    params = defaultParams,
    loadOnDemand = false,
    loadingFeedback = true,
    requiredParams,
    preRequisites = true,
    initialLoadingState = false,
  } = options
  const {
    prefs: { currentTenant, currentRegion },
  } = useScopedPreferences()
  const getParamsPermutations = useCallback(
    memoize((memoizedParams) => {
      const indexedParams = cache
        ? memoizeParams(
            pipe(
              pickAll(ensureArray(indexBy)),
              reject(either(isNil, equals(allKey))),
            )(memoizedParams),
          )
        : memoizedParams

      if (indexBy?.includes('clusterId')) {
        return paramsCartesianProduct({
          ...indexedParams,
          clusterId: parseClusterIdsFromParams(indexedParams),
        })
      }
      return paramsCartesianProduct(indexedParams)
    }),
    [indexBy, cache],
  )

  // Memoize the params dependency to make sure it really changed and not just got a new ref
  const memoizedParams = memoizeParams(params)
  const indexedParamsArr = useMemo<Array<P>>(() => getParamsPermutations(memoizedParams), [
    memoizedParams,
    getParamsPermutations,
  ])

  const requiredParamsProvided = useMemo(
    () =>
      requiredParams &&
      Object.keys(pick(requiredParams, memoizedParams)).length === requiredParams.length,
    [requiredParams, memoizedParams],
  )
  const paramsSelector = useMemo(() => {
    return pipe(path([cacheStoreKey, paramsStoreKey, cacheKey]), arrayIfNil)
  }, [cacheKey])
  const cachedParams = useSelector(paramsSelector)
  const loadingKeys = useIsDataLoadingSelector(initialLoadingState, ...dependentCacheKeys, cacheKey)
  // Check if any of the keys are in loading state
  const loading = !!loadingKeys.length && preRequisites
  const message = loading
    ? `Loading ${loadingKeys.map((cacheKey) => entityNamesByKey[cacheKey]).join(', ')}...`
    : undefined

  // The following function will handle the calls to the data loading and
  // set the loading state variable to true in the meantime, while also taking care
  // of the sequencing of multiple concurrent calls
  // It will set the result of the last data loading call to the "data" state variable
  const loadData = useCallback(
    async (refetch = false, updateLoadingState = loadingFeedback) => {
      await someAsync(
        indexedParamsArr.reduce((acc, indexedParamsPermutation) => {
          // Search either for the provided params or an empty object (it means we fetched for ALL items)
          if (
            refetch ||
            !cachedParams.find(either(equals(indexedParamsPermutation), equals(emptyObj)))
          ) {
            acc.push(
              // @ts-ignore Although correct, this is too much for the TS engine to understand
              action.call(
                { ...memoizedParams, ...indexedParamsPermutation },
                {
                  updateLoadingState,
                  refetch,
                },
              ),
            )
          }
          return acc
        }, []),
      )
    },
    [indexedParamsArr, loadingFeedback, cachedParams],
  )

  // Load the data on component mount and every time the params change
  useEffect(() => {
    if (((!loadOnDemand && !requiredParams) || requiredParamsProvided) && preRequisites) {
      loadData()
    }
  }, [loadData, currentTenant, currentRegion, loadOnDemand, requiredParamsProvided, preRequisites])

  return { message, reload: loadData, loadingKeys, loading }
}

export default useListAction
