import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { pluck, path } from 'ramda'
import { emptyArr, isNilOrEmpty } from 'utils/fp'
import useReactRouter from 'use-react-router'
import useToggler from 'core/hooks/useToggler'
import { pathJoin } from 'utils/misc'
import CreateButton from 'core/components/buttons/CreateButton'
import Grid from 'core/elements/grid'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { GridProps } from 'core/elements/grid/Grid'
import DeleteAction from 'core/actions/DeleteAction'
import { IDataKeys } from 'k8s/datakeys.model'
import GridSearchFilter from 'core/elements/grid/GridSearchFilter'
import { GridGlobalFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { ArrayElement } from 'core/actions/Action'
import GridDefaultDeleteButton from 'core/elements/grid/buttons/GridDefaultDeleteButton'
import GridDefaultActionButton from 'core/elements/grid/buttons/GridDefaultActionButton'
import {
  BatchActionButtonProps,
  GridBatchActionSpec,
} from 'core/elements/grid/hooks/useGridSelectableRows'
import { HeaderTitlePortal, HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import Breadcrumbs from 'core/elements/breadcrumbs'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'

export interface ListContainerProps<D extends keyof IDataKeys, T = ArrayElement<IDataKeys[D]>>
  extends GridProps<T> {
  dataKey?: DataKeys
  searchTargets: Array<keyof T | string>
  nameProp?: keyof T

  addText?: string
  addUrl?: string | { (selectedItem: T, id: string): string }
  addCond?: () => boolean
  AddButtonComponent?: FC<BatchActionButtonProps<T>>
  AddDialogComponent?: FC<
    PropsWithChildren<{
      onClose: () => void
    }>
  >

  editText?: string
  editUrl?: string | { (selectedItem: T, id: string): string }
  editCond?: (selectedItems: T[]) => boolean
  editDisabledInfo?: string
  EditButtonComponent?: FC<BatchActionButtonProps<T>>
  EditDialogComponent?: FC<
    PropsWithChildren<{
      onClose: () => void
      rows: T[]
    }>
  >

  deleteText?: string
  deleteAction?: DeleteAction<D>
  deleteCond?: (selectedItems: T[]) => boolean
  deleteDisabledInfo?: string
  DeleteButtonComponent?: FC<BatchActionButtonProps<T>>
  DeleteDialogComponent?: FC<
    PropsWithChildren<{
      onClose: () => void
      rows: T[]
    }>
  >
  showBreadcrumbs?: boolean
  extraHeaderContent?: ReactNode
  extraToolbarContent?: ReactNode
  label?: string
  getParamsUpdater: (...keys: string[]) => (...values: unknown[]) => void
  tooltip?: ReactNode
}

export const getDeleteConfirmText = (selectedItems, nameProp = 'name') => {
  if (isNilOrEmpty(selectedItems)) {
    return ''
  }
  const pluckName = pluck(nameProp)
  const selectedNames = pluckName(selectedItems).join(', ')
  return `This will permanently delete the following: ${selectedNames}`
}

export default function ListContainer<D extends keyof IDataKeys, T = ArrayElement<IDataKeys[D]>>(
  props: ListContainerProps<D, T>,
) {
  const {
    dataKey,
    uniqueIdentifier,
    searchTargets,
    nameProp = 'name',
    batchActions = [],
    globalFilters: customGlobalFilters = [],

    addText = 'Add',
    AddButtonComponent = CreateButton,
    addUrl,
    addCond,
    AddDialogComponent,

    editText = 'Edit',
    EditButtonComponent = GridDefaultActionButton,
    EditDialogComponent,
    editUrl,
    editCond,

    deleteText = 'Delete',
    deleteAction,
    deleteCond,
    DeleteButtonComponent = GridDefaultDeleteButton,
    DeleteDialogComponent,

    showBreadcrumbs = true,
    extraHeaderContent,
    loading,
    label = entityNamesByKey[dataKey],

    getParamsUpdater,
  } = props

  const { history } = useReactRouter()
  const deletePromise = useRef<(value?: unknown) => void | Promise<void>>()
  const [showingConfirmDialog, toggleConfirmDialog] = useToggler()
  const [showingEditDialog, toggleEditDialog] = useToggler()
  const [showingAddDialog, toggleAddDialog] = useToggler()
  const [selectedItems, setSelectedItems] = useState<T[]>(emptyArr)

  const { update: handleRemove, updating: deleting } = deleteAction
    ? useUpdateAction(deleteAction)
    : { update: null, updating: false }

  const globalFilters = useMemo<Array<GridGlobalFilterSpec<T, Record<string, unknown>>>>(
    () => [
      {
        key: 'search',
        equalityComparerFn: (item, value) => {
          return !!searchTargets.find((key) =>
            String(
              item.hasOwnProperty(key) ? item[String(key)] : path(String(key).split('.'), item),
            )
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()),
          )
        },
        FilterComponent: GridSearchFilter,
      } as GridGlobalFilterSpec<T, Record<string, unknown>, 'search', string>,
      ...customGlobalFilters,
    ],
    [searchTargets],
  )

  const deleteConfirmText = useMemo(() => getDeleteConfirmText(selectedItems, String(nameProp)), [
    selectedItems,
  ])

  const allBatchActions = useMemo(() => {
    const defaultBatchActions: GridBatchActionSpec<T>[] = []

    if (EditDialogComponent || editUrl) {
      defaultBatchActions.push({
        label: editText,
        cond: editCond,
        handleAction: async (selectedItems) => {
          if (editUrl) {
            const [selectedRow] = selectedItems
            const selectedId = String(selectedRow[uniqueIdentifier])
            const urlResult =
              typeof editUrl === 'function'
                ? editUrl(selectedRow, selectedId)
                : pathJoin(editUrl, selectedId)
            history.push(urlResult)
            return
          }
          setSelectedItems(selectedItems)
          toggleEditDialog()
        },
        BatchActionButton: EditButtonComponent,
      })
    }

    if (handleRemove) {
      defaultBatchActions.push({
        label: deleteText,
        cond: deleteCond,
        handleAction: async (selectedItems) => {
          setSelectedItems(selectedItems)
          toggleConfirmDialog()
          // Stash the promise resolver, so it can be used to resolve later
          // in response to a user interaction (delete confirmation).
          return new Promise((resolve) => {
            deletePromise.current = resolve
          })
        },
        BatchActionButton: DeleteButtonComponent,
      })
    }

    return [...defaultBatchActions, ...batchActions]
  }, [uniqueIdentifier, handleRemove, batchActions])

  const handleDeleteConfirm = useCallback(async () => {
    toggleConfirmDialog()
    await Promise.all(selectedItems.map(handleRemove))
    await deletePromise.current()
  }, [selectedItems, handleRemove])

  const handleAdd = () => {
    trackEvent(`Clicked ${addText}`)
    if (addUrl) {
      history.push(addUrl)
    } else if (AddDialogComponent) {
      toggleAddDialog()
    }
  }

  const addEnabled = useMemo(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    const didAddCondPass = !!addCond ? addCond() : true
    const isCustomAddButton = AddButtonComponent !== CreateButton
    return (!!AddDialogComponent || !!addUrl || isCustomAddButton) && didAddCondPass
  }, [addCond])

  return (
    <>
      {showBreadcrumbs && (
        <HeaderTitlePortal>
          <Breadcrumbs />
        </HeaderTitlePortal>
      )}
      {AddDialogComponent && showingAddDialog && (
        <AddDialogComponent onClose={toggleAddDialog}>{addText}</AddDialogComponent>
      )}
      {EditDialogComponent && showingEditDialog && (
        <EditDialogComponent onClose={toggleEditDialog} rows={selectedItems}>
          {editText}
        </EditDialogComponent>
      )}
      {DeleteDialogComponent && showingConfirmDialog && (
        <DeleteDialogComponent onClose={toggleConfirmDialog} rows={selectedItems}>
          {deleteText}
        </DeleteDialogComponent>
      )}
      {!DeleteDialogComponent && handleRemove && showingConfirmDialog && (
        <ConfirmationDialog
          open={showingConfirmDialog}
          text={deleteConfirmText}
          onCancel={toggleConfirmDialog}
          onConfirm={handleDeleteConfirm}
        />
      )}
      {addEnabled && (
        <HeaderPrimaryActionPortal>
          {extraHeaderContent}
          <AddButtonComponent onClick={handleAdd}>{addText}</AddButtonComponent>
        </HeaderPrimaryActionPortal>
      )}
      <Grid<T>
        {...props}
        label={label}
        globalFilters={globalFilters}
        batchActions={allBatchActions}
        loading={loading || deleting}
        onSortChange={getParamsUpdater && getParamsUpdater('orderBy', 'orderDirection')}
        onRowsPerPageChange={getParamsUpdater && getParamsUpdater('rowsPerPage')}
        onColumnsChange={getParamsUpdater && getParamsUpdater('visibleColumns', 'columnsOrder')}
      />
    </>
  )
}
