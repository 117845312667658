import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import {
  IVirtualMachineDetailsPageTabs,
  VirtualMachineCreateTypes,
  VirtualMachineResourceTypes,
} from 'app/plugins/kubevirt/components/virtual-machines/model'
import { IVirtualMachineInstanceDetailsPageTabs } from 'app/plugins/kubevirt/components/virtual-machines/vmi-model'
import { Route } from 'core/plugins/route'

const metadata = {
  showGlobalFilters: true,
}

export const kubevirtRoutes = {
  kubevirt: {
    dashboard: Route.register({
      url: `${pluginRoutePrefix}/dashboard`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Kubevirt:Dashboard',
    }),
    overview: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/overview`,
      id: 'vmsOverview',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:Overview',
      metadata,
    }),
  },
  virtualMachineInstances: {
    details: Route.register<{
      clusterId: string
      name: string
      tab?: IVirtualMachineInstanceDetailsPageTabs
    }>({
      id: 'vmiDetails',
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/virtual-machine-instances/:name/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['virtual machines', 'vmsOverview'],
        [':name', 'vmiDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'VirtualMachineInstances:Details',
      tab: 'tab',
      metadata,
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/add/new`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        createType: VirtualMachineResourceTypes.VirtualMachineInstance,
      },
      name: 'VirtualMachineInstances:Add:NewVM',
      metadata,
    }),
    import: {
      url: Route.register({
        url: `${pluginRoutePrefix}/virtual-machines/import/url`,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          createType: VirtualMachineCreateTypes.ImportURL,
        },
        name: 'VirtualMachineInstances:Import:URL',
        metadata,
      }),
      disk: Route.register({
        url: `${pluginRoutePrefix}/virtual-machines/import/disk`,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          createType: VirtualMachineCreateTypes.ImportDisk,
        },
        name: 'VirtualMachineInstances:Import:Disk',
        metadata,
      }),
    },
    clone: {
      pvc: Route.register({
        url: `${pluginRoutePrefix}/virtual-machines/clone/pvc`,
        defaultParams: {
          plugin: AppPlugins.KubeVirt,
          createType: VirtualMachineCreateTypes.ClonePVC,
        },
        name: 'VirtualMachineInstances:Clone:PVC',
        metadata,
      }),
    },
  },
  // These routes will be changed when adding vm/vmi details, for now just
  // need them not to conflict with above routes
  virtualMachines: {
    details: Route.register<{
      clusterId: string
      id: string
      tab?: IVirtualMachineDetailsPageTabs
    }>({
      id: 'vmDetails',
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/virtual-machines/:id/:tab`,
      breadcrumbs: new Map([
        ['workloads', null],
        ['virtual machines', 'vmsOverview'],
        [':id', 'vmDetails'],
      ]),
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        tab: 'overview',
      },
      name: 'VirtualMachines:Details',
      tab: 'tab',
      metadata,
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/add/new`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
        createType: VirtualMachineCreateTypes.AddNew,
      },
      name: 'VirtualMachines:Add:NewVM',
      metadata,
    }),
    edit: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/clusters/:clusterId/vms/edit/:id`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:VM:Edit',
      metadata,
    }),
  },
  liveMigrations: {
    overview: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/live-migrations`,
      id: 'liveMigrationsOverview',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:LiveMigrations',
      metadata,
    }),
  },
  instanceTypes: {
    list: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/instance-types`,
      id: 'instanceTypesList',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:InstanceTypes',
      metadata,
    }),
    add: Route.register({
      url: `${pluginRoutePrefix}/virtual-machines/instance-types/new`,
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'VirtualMachines:InstanceTypes:Add',
      metadata,
    }),
  },
  events: {
    list: Route.register({
      url: `${pluginRoutePrefix}/events`,
      id: 'kubevirtEventsList',
      defaultParams: {
        plugin: AppPlugins.KubeVirt,
      },
      name: 'Kubevirt:Events',
    }),
  },
}
